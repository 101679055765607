*, *:after, *:before {
  box-sizing: border-box;
  font-family: 'EB Garamond';
  color: $copy;
}

html {
  // prevent text size adjust after orientation change, without disabling user zoom
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: 'EB Garamond';
  font-size: 70%;
  @include breakpoint($medium) {
    font-size: 86%;
  }
}

body {
  background-color: #f7f0ea;
}

h1 {
  color: $primarycolor;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: rem(10);
  word-spacing: 0;
  // ligatures 
  font-feature-settings: "liga" 1;
  font-display: swap;

  span.firstline {
    color: $primarycolor;
    font-family: 'Lato';
    font-size: rem(30);
    display: block;
    font-weight: 300;
    line-height: 1.2;
  }

  span.smallspace {
    color: $primarycolor;
  }
}

h4 {
  @include p;
  font-weight: 700;
  padding-top: 2rem;
}

h5 {
  @include p;
  font-weight: 700;
}

li {
  @include p;
}

.small li {
  font-size: 1.2rem;
}

.maxwidthcontainer {
  margin: 0 auto;
  max-width: 115rem;
}

@include breakpoint($threecolumns) {
  .columns-3 {
    columns: 3 auto;
    column-gap: rem($pd);
  }
}

.small p, .small h5 {
  font-size: 1.2rem;
}

.smallspace {
  word-spacing: -4px;
}


sub, sup {
  /* Specified in % so that the sup/sup is the
  right size relative to the surrounding text */
  font-size: 75%;

  /* Zero out the line-height so that it doesn't
  interfere with the positioning that follows */
  line-height: 0;

  /* Where the magic happens: makes all browsers position
  the sup/sup properly, relative to the surrounding text */
  position: relative;

  /* Note that if you're using Eric Meyer's reset.css, this
  is already set and you can remove this rule */
  vertical-align: baseline;
}

sup {
  /* Move the superscripted text up */
  top: -0.5em;
}

sub {
  /* Move the subscripted text down, but only
  half as far down as the superscript moved up */
  bottom: -0.25em;
}

em {
  font-style: italic;
}

a.regularlink {
  color: $blue;
  font-weight: 300;
}

a#read-more {
  font-weight: 500;
  color: $primarycolor;
  font-family: 'Lato';
  font-size: 125%;
  display: inline-block;
  margin-top: rem($pm);
  border-bottom: 1px solid white;
  &:hover {
    cursor: pointer;
  }
}

video {
  width: 100%;
}

.mainaside {
  video {
    margin-top: 0.5rem;
    position: relative;
    left: -2rem;
    width: calc(100% + 2rem);
  }
}

.heading {
  color: $primarycolor;
  font-weight: 500;
}

.light {
  line-height: 1.4;
  font-weight: 300;
}

a.scroll {
  margin: rem($pm * 2) rem($pm) rem($pm) rem($pm);
  display: inline-block;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embedded-video {
  padding: 56.25% 0 0 0;
  position: relative;
}

.nomargin {
  margin-left: 0 !important;
}
