h2 {
  font-size: rem(36);
  line-height: 1.4;
  margin-bottom: 2rem;
  font-weight: 500;
  color: $primarycolor;
}

h3 {
  font-family: 'Lato';
  font-size: rem(24);
  line-height: 1.6;
  font-weight: 700;
  color: $primarycolor;
  margin-bottom: 2px; 
}

@mixin p {
  font-size: rem(24);
  line-height: 1.6;
  color: $copy;
  hyphens: auto;
}

p {
  @include p;
}
