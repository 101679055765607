/* eb-garamond-regular - cyrillic_latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/eb-garamond-v12-cyrillic_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/eb-garamond-v12-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/eb-garamond-v12-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/eb-garamond-v12-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/eb-garamond-v12-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/eb-garamond-v12-cyrillic_latin-regular.svg#EBGaramond') format('svg'); /* Legacy iOS */
}
/* eb-garamond-500 - cyrillic_latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/eb-garamond-v12-cyrillic_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/eb-garamond-v12-cyrillic_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/eb-garamond-v12-cyrillic_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/eb-garamond-v12-cyrillic_latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/eb-garamond-v12-cyrillic_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/eb-garamond-v12-cyrillic_latin-500.svg#EBGaramond') format('svg'); /* Legacy iOS */
}
/* eb-garamond-600 - cyrillic_latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/eb-garamond-v12-cyrillic_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/eb-garamond-v12-cyrillic_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/eb-garamond-v12-cyrillic_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/eb-garamond-v12-cyrillic_latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/eb-garamond-v12-cyrillic_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/eb-garamond-v12-cyrillic_latin-600.svg#EBGaramond') format('svg'); /* Legacy iOS */
}
/* eb-garamond-italic - cyrillic_latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/eb-garamond-v12-cyrillic_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/eb-garamond-v12-cyrillic_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/eb-garamond-v12-cyrillic_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/eb-garamond-v12-cyrillic_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/eb-garamond-v12-cyrillic_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/eb-garamond-v12-cyrillic_latin-italic.svg#EBGaramond') format('svg'); /* Legacy iOS */
}
/* eb-garamond-600italic - cyrillic_latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/eb-garamond-v12-cyrillic_latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/eb-garamond-v12-cyrillic_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/eb-garamond-v12-cyrillic_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/eb-garamond-v12-cyrillic_latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/eb-garamond-v12-cyrillic_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/eb-garamond-v12-cyrillic_latin-600italic.svg#EBGaramond') format('svg'); /* Legacy iOS */
}
/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lato-v16-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'),
       url('../fonts/lato-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/lato-v16-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Light Italic'), local('Lato-LightItalic'),
       url('../fonts/lato-v16-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin-300italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
       url('../fonts/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lato-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
       url('../fonts/lato-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}
