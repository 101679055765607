#mainnav {
  margin-bottom: rem($pd);
  padding-top: rem($pd);
  margin: rem($pm);

  input[type=checkbox] {
    @include hidden;
  }

  li {
    width: 100%;
    height: 4rem;
    position: relative;
    line-height: 1.3;
    & + li {
      border-top: 1px solid $copy;
    }
    &:nth-of-type(5) {
      height: 6rem;
    }
  }

  a {
    text-decoration: none;
    width: 100%;
    letter-spacing: .3px;
    &.active, &:hover {
      letter-spacing: 0;
      color: $primarycolor;
    }
    &:first-child {
      letter-spacing: 0;
    }
  }

  ul a {
    position: absolute;
    font-family: 'Lato';
    top: 1rem;
    font-weight: 300;
    &.active, &:hover {
      font-weight: 400;
    }
    font-size: rem(24);
  }

  h1 a {
    position: static;
    color: $primarycolor;
    &.active, &:hover {
      border-bottom: 1px solid $primarycolor;
    }
  }
}

@include breakpoint(0, $medium - 1) {

  #mainnav {
    background: white;
    position: relative;

    ul {
      width: 0;
      visibility: hidden;
      opacity: 0;
      transform: translateX(50%);
      position: absolute;
      z-index: -1;
      transition: all 0.2s ease;
      min-height: 100vh;
      background: white;

      li {
        background: white;
        width: 100%;
        padding-right: rem($pm);
      }
    }

    input[type=checkbox]:checked ~ ul {
      visibility: visible;
      width: 100%;
      z-index: 3;
      opacity: 0.98;
      transform: translateX(0);
    }

    button, label {
      position: absolute;
      top: 0;
      right: 0;
    }

    label {
      width: 4.5rem;
      height: 4rem;
      z-index: 1;
      margin-right: -1rem;
    }

  }
}

@include breakpoint($medium) {

  .layout {
    display: flex;
  }

  #mainnav {
    background: white;
    margin: 0 1.25rem 0 rem($pd / 2);
    border-right: 1px solid $copy;
    flex-shrink: 0;
    flex-grow: 0;
    padding-left: 2rem;

    li {
      width: $navcolumnwidth;
      background: white;
    }
  }
}

@include breakpoint($medium, $large - 1) {

  h1.original {
    position: fixed;
    transform: translateX(-30.5rem);
    transition: transform 0.4s ease-out;
    background: white;
    margin-top: -2.5rem;
    padding: 1.2rem 1.1rem 1.3rem 0;
    width: 25.1rem;
    z-index: 2;
  }

  #mainnav {
    ul {
      position: fixed;
      padding-top: 6.3rem;
      transform: translateX(-27.5rem);
      transition: transform 0.4s ease-out;
      background: white;
      z-index: 1;
      padding-right: 1rem;
      height: 100vh;
    }

    label {
      position: fixed;
      top: 1.2rem;
      left: .5rem;
      display: block;
      width: 2.1rem;
      height: 2rem;
      z-index: 3;
      &:hover {
        cursor: pointer;
      }
    }

    button {
      position: fixed;
      top: 0.4rem;
      left: .65rem;
      z-index: 2;
    }

    input[type=checkbox]:checked {
      & ~ h1 {
        opacity: 1;
        transform: translateX(0);
        border-right: 1px solid $copy;
      }

      & ~ ul {
        opacity: 1;
        transform: translateX(0);
        border-right: 1px solid $copy;
      }
    }
  }

}

@include breakpoint($large) {
  #mainnav {
    margin-right: rem($pd * 1.25);
    height: 35.5rem;
    padding-right: 3rem;
    padding-left: 0;

    h1 {
      text-align: right;
    }

    h1.original {
      text-align: right;
    }

    ul {
      transform: translateX(0);
      padding-top: 0;
    }
    
    li {
      text-align: right;
      a {
        right: 0;
      }
    }

    button, label {
      position: absolute;
      top: 0;
      right: 0;
    }


    .hamburger {
      @include hidden;
    }
  }


}
