#footer {
  position: absolute;
  bottom: 0;
  padding-bottom: 2rem;
  left: 0;
}

.backtotop {
  text-align: right;
}

#footer-repeated {
  @include hidden;
}

.footerwrapper {
  font-family: 'Lato';
  font-weight: 300;
  margin-left: rem($pd / 2);
  margin-right: rem($pd * 1.25);

  p {
    font-size: rem(19);
    line-height: 1.4;
    a {
      text-decoration: none;
      color: $primarycolor;
      &:hover {
        border-bottom: 1px solid $primarycolor;
      }
    }
  }

  .copyright {
    color: $primarycolor;
    font-weight: 400;
    margin-bottom: 1rem;
  }
}


@mixin alternative-footer {
  #footer {
    @include hidden;
  }

  #footer-repeated {
    display: block;
    width: 100%;
    visibility: visible;
    background: white;
    .footerwrapper {
      margin: 0;
      padding-bottom: 1.25rem;
      a {
        font-weight: 300;
      }
    }
  }
}

@include breakpoint(0, $medium - 1) {
  #footer {
    @include hidden;
  }
}

@include breakpoint(0, $large - 1) {
  @include alternative-footer;
}

@include breakpoint($medium) {
  .backtotop {
    @include hidden;
  }
}

@include breakpoint($large) {
  #footer {
    width: 28.3rem;
    border-right: 1px solid $copy;
    text-align: right;
  }

}

@media screen and (max-height: 700px) {
  @include alternative-footer;
}
