@if index($hamburger-types, spring) {
  /*
   * Spring
   */
  input[type=checkbox]:checked ~ button.hamburger--spring {

    .hamburger-inner {
      transition-delay: 0.22s;
      background-color: transparent !important;

      &::before {
        top: 0;
        transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(45deg);
      }

      &::after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(-45deg);
      }
    }
  }
}
