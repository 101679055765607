.layout {
  background-color: white;
}

#main {
  margin: rem($pm);

  a {
    color: $primarycolor;
    font-weight: 700;
    text-decoration: none;
    &:hover {
      border-bottom: 1px solid $primarycolor;
    }
  }

  h1 a {
    font-weight: 500;
  }

  h1.repeated {
    @include hidden;
  }

  .maincontents {
    flex-basis: 66.6%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 2rem;

    p + p {
      text-indent: 3rem;
    }

    ul.regularlist {
      list-style: disc;
      padding-left: 2rem;
    }
  }

  .mainaside {
    flex-basis: 33.4%;
    flex-grow: 0;
    flex-shrink: 0;
    font-family: 'Lato';
    font-size: rem(19);
    padding-bottom: 2rem;

    p {
      font-size: rem(19);
      font-weight: 300;
      margin-left: 2rem;
      position: relative;
      strong {
        color: $primarycolor;
        font-weight: 400;
      }
    }

    p + p {
      margin-top: 1rem;
    }

    a {
      font-weight: 400;

    }

    span.icon {
      position: absolute;
      left: 0;
      top: 2px;
      display: block;
      width: 1rem;
      height: 1rem;
      margin-left: -2rem;
    }

    span.icon-kontakt {
      top: 0.5px;
      width: 1.2rem;
    }

    span.icon-literatur {
      top: 1px;
      width: 1.2rem;
    }

    span.icon-gestaltung {
      width: 1.2rem;
    }

    span.icon-programmierung {
      color: $primarycolor;
      font-weight: 700;
      top: 1px;
      width: rem(20);
      height: rem(18);
      letter-spacing: -1px;
    }

    svg {
      object-fit: cover;
    }

    ol.literatur {
      list-style: none;
      list-style-position: inside;
      counter-reset: my-ol-style;
      li {
        position: relative;
        font-size: rem(19);
        font-weight: 300;
        padding: 0.5rem 0 0 2rem;
        counter-increment: my-ol-style;
        &:before {
          font-family: 'Lato';
          font-size: rem(19);
          font-weight: 300;
          position: absolute;
          left: 0;
          top: 0.5rem;
          content: counter(my-ol-style) " ";
        }
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    .figure {
      &:last-of-type {
        &:after {
          border: none;
        }
      }
      margin-bottom: 2rem;
      p {
        margin-left: 0;
      }
    }

    .caption {
      padding-top: 1rem;
      font-weight: 300;
    }

  }
}

#publications {
  font-size: 86%;

  ul + h5 {
    margin-top: 1rem;
  }

  div {
    padding-top: rem($pm / 2);
    padding-bottom: rem($pm * 2 );
  }

  ul {
    padding-left: 0.25rem;
  }

  li {
    text-indent: 0rem;
    margin-left: 1em;
    padding-left: 0;
    list-style-type: disc;
    // list-style-position: inside;
  }
}

@include breakpoint(0, $medium - 1) {
  .zur-person {
    #main {
      .mainaside {
        padding-bottom: 0;
      }
      #publications {
        display: block;
      }
    }
  }
}


@include breakpoint($medium, $large - 1) {
  #main {
    h1.repeated {
      visibility: visible;
      display: block;
    }
  }
}

@include breakpoint($medium) {
  .layout {
    min-height: 100vh;
  }

  .layout.maxwidthcontainer {
    position: relative;
  }

  #main {
    margin-top: 0;
    padding-top: 1rem;
    max-height: 100vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    border-right: 0.5rem solid $primarycolor;
    padding-right: rem($pd);

  }

  .main_and_aside {
    display: flex;
    padding-bottom: 0;
  }

  .maincontents {
    margin-right: rem($pd * 1.25 / 2);
  }

  .mainaside {
    margin-top: 2.85rem;
    margin-left: rem($pd * 1.25 / 2);
    padding-right: rem($pd * 1.25 / 2);
    padding-bottom: 17rem;
  }

  #publications {
    div {
      padding-top: rem($pd / 2);
      padding-bottom: rem($pd * 2);
    }
  }

}

@include breakpoint($large) {
  #main {
    padding-top: 8.5rem;
    margin: 0;
  }
}
