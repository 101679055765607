// colors
$primarycolor: #925f2e;
$blue: #0085cc;
$copy: #3e484f;

// paddings
$pd: 40;
$pm: 20;

// breakpoints
$medium: 650;
$large: 1200;
$threecolumns: 1000;

// layout
$navcolumnwidth: 24rem; 

// hamburger
$hamburger-padding-x           : 0 !default;
$hamburger-padding-y           : 15px !default;
$hamburger-layer-width         : 26px !default;
$hamburger-layer-height        : 2px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : $primarycolor !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
$hamburger-types: (
  spring,
) !default;
